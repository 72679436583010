import Vue from "vue";
import Vuex from "vuex";
import { UserRole } from "./definition";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  plugins: [createPersistedState()],
  state: {
    barColor: "rgba(67, 67, 67, .8), rgba(67, 67, 67, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    drawer: null,
    shoppingCart: false,
    shoppingItems: [],
    token: "",
    username: "",
    // expiredTime: '',
    title: "",
    role: UserRole.Unpaid,
    avatar: null,
    max_urban_renewal: null,
    max_issue: null,
    company: null,
    // DECIMAL_PLACES
    decimal_places: 3,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SHOPPING_CART(state, payload) {
      state.shoppingCart = payload;
    },
    setToken(state, data) {
      state.token = data.token;
      // state.username = data.username;
      this.commit("setUsername", data.username);
      state.role = data.role;
      // state.avatar = data.avatar;
      this.commit("setAvatar", data.avatar);
      state.max_urban_renewal = data.max_urban_renewal;
      state.max_issue = data.max_issue;
      // state.expiredTime = data.expiredTime;
      state.company = data.company;
    },
    setUsername(state, data) {
      state.username = data;
    },
    setAvatar(state, data) {
      state.avatar = data;
    },
    setTitle(state, data) {
      state.title = data;
    },
    setQuota(state, data) {
      state.max_urban_renewal = data.max_urban_renewal;
      state.max_issue = data.max_issue;
    },
    addShoppingItems(state, item) {
      let shoppingItem = state.shoppingItems.find((element) => {
        return element.id == item.id;
      });
      if (shoppingItem) {
        shoppingItem["count"] += 1;
      } else {
        let newItem = Object.assign({}, item);
        newItem["count"] = 1;
        let itemNo = 1;
        if (state.shoppingItems.length != 0) {
          itemNo += state.shoppingItems[state.shoppingItems.length - 1].itemNo;
        }
        newItem["itemNo"] = itemNo;
        state.shoppingItems.push(newItem);
        state.shoppingItems.sort((a, b) => {
          return a.itemNo - b.itemNo;
        });
      }
    },
    removeShoppingItems(state, item) {
      let shoppingItem = state.shoppingItems.find((element) => {
        return element.itemNo == item.itemNo;
      });
      if (shoppingItem && shoppingItem["count"] > 1) {
        shoppingItem["count"] -= 1;
      } else {
        state.shoppingItems = state.shoppingItems.filter(
          (element) => element.itemNo != item.itemNo
        );
      }
    },
    clearShoppingCart(state) {
      state.shoppingItems = [];
    },
    set_decimal_places(state, value) {
      state.decimal_places = value;
    },
  },
  actions: {
    sumbitToken({ commit }, data) {
      commit("setToken", data);
    },
  },
});
